<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Staff Referral Code</h1>
      <div class="flex my-5">
        <button
          @click="handleBackBtn"
          class="
                    block
                    uppercase
                    shadow
                    ml-2
                    text-sm
                    bg-blue-900
                    text-white
                    hover:bg-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    py-3
                    px-8
                    rounded
                    
                  "
        >
          &laquo; Back
        </button>
      </div>
      <div class="mt-14">
        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2 mx-auto">
          <!-- Column Content -->
          <div
            class="w-full border-2 drop-shadow-sm border-gray-100 flex flex-col bg-white rounded-lg overflow-hidden cursor-pointer"
          >
            <div class="border-t border-gray-200 p-5">
              <p class="font-medium mt-2 text-sm">Add Staff Referral Code</p>
              <div v-if="firstPage">
                <div class="drop-shadow-2xl rounded-md p-3 mt-5">
                  <p class="font-medium text-xs">
                    First Name<span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                      name=""
                      placeholder="Enter First Name"
                      id=""
                      v-model="firstName"
                    />
                  </p>
                  <p
                    v-if="errors.firstName && firstName === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.firstName }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-5">
                  <p class="font-medium text-xs">
                    Last Name<span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                      name=""
                      placeholder="Enter Last Name"
                      id=""
                      v-model="lastName"
                    />
                  </p>
                  <p
                    v-if="errors.lastName && lastName === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.lastName }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-5">
                  <p class="font-medium text-xs">
                    Traction Email<span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="email"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                      name=""
                      placeholder="Enter Traction Email"
                      id=""
                      v-model="email"
                    />
                  </p>
                  <p
                    v-if="errors.email && email === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.email }}
                  </p>
                </div>
                <div class="drop-shadow-2xl rounded-md p-3 mt-5">
                  <p class="font-medium text-xs">
                    Referral Code<span class="text-red-600">*</span>
                  </p>
                  <p class="text-xs">
                    <input
                      type="text"
                      class="shadow appearance-none border text-xs rounded w-full py-2 leading-5 px-3 text-gray-700 mt-1  focus:outline-none focus:shadow-outline"
                      name=""
                      placeholder="Enter Referral Code"
                      id=""
                      v-model="referralCode"
                    />
                  </p>
                  <p
                    v-if="errors.referralCode && referralCode === ''"
                    class="text-xs text-red-600 mt-2"
                  >
                    {{ errors.referralCode }}
                  </p>
                </div>
                <div class="p-3">
                  <button
                    @click="handleCancel"
                    class="inline uppercase shadow bg-yellow-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    @click="handleUpload"
                    class="inline uppercase shadow ml-5 bg-blue-600 mt-5 leading-6 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      moment,
      promptDiv: false,
      isLoading: false,
      firstPage: true,
      firstName: "",
      lastName: "",
      email: "",
      referralCode: "",
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        referralCode: "",
      },
      files: [],
    };
  },
  computed: {},
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    async handleUpload() {},
    handleBackBtn() {
      this.$router.go(-1);
    },
    handleCancel() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.referralCode = "";
    },
  },
};
</script>
